import { useEffect, useRef } from "react";
import SliderComponent, { RangeSliderComponent } from "./Slider";
import { rangeCalculations } from "../../helpers/range-calculations";
import FlexWrapper from "./FlexWrapper";
import Repeater from "./Repeater";

export default function RangeGroup(props) {
	const ranges = useRef(props.data);
	const sliderCount = useRef(props.data.length);

	const updateRefs = (data) => {
		ranges.current = data;
		sliderCount.current = data.length;
	};

	useEffect(() => {
		updateRefs(props.data);
	}, [props.data]);

	const resetRanges = (count) => {
		const incr = Math.floor(props.max / count);
		const newRanges = Array.from(Array(count).keys()).map((i) => {
			const base =
				i > sliderCount.current - 1 ? ranges.current[i - 1] : ranges.current[i];
			return {
				...base,
				from: i === 0 ? 0 : i * incr + 1,
				to: i === count - 1 ? props.max : (i + 1) * incr,
			};
		});

		updateRefs(newRanges);
		props.onChange(newRanges);
	};

	const onSliderChange = ([from, to], index) => {
		const rangeChange = ranges.current.map((rating) => ({
			lower: rating.from,
			upper: rating.to,
		}));
		rangeChange[index].lower = from;
		rangeChange[index].upper = to;

		const newRanges = rangeCalculations(rangeChange, index, props.max).map(
			(nr, i) => ({
				...ranges.current[i],
				from: nr.lower,
				to: nr.upper,
			})
		);

		updateRefs(newRanges);
		props.onChange(newRanges);
	};

	return (
		<Repeater
			items={props.data || [{}]}
			disableAdd={props.data.length >= 5}
			add={() => {
				resetRanges(props.data.length + 1);
			}}
			subtract={() => {
				resetRanges(props.data.length - 1);
			}}
			template={(rating, index) => {
				const isFirstTime = index === 0;
				const isLastItem = index === props.data.length - 1;

				const step = Math.floor(props.max / 4);
				const multiple = Math.ceil(props.max / step);

				const marks = {};
				for (let i = 0; i <= multiple; i++) {
					const mark = step * i;
					if (mark >= props.max * 0.95) {
						marks[props.max] = `${props.max}+`;
						continue;
					}

					marks[mark] = `${mark}`;
				}

				const sliderProps = {
					max: props.max,
					marks: marks,
					onChange:
						index === 0
							? (value) => onSliderChange([0, value], index)
							: isLastItem
							? (value) => onSliderChange([value, props.max], index)
							: (values) => onSliderChange(values, index),
				};

				return (
					<>
						<FlexWrapper style={{ width: "100%" }} key={index}>
							{isFirstTime ? (
								<SliderComponent {...sliderProps} value={rating.to} />
							) : (
								<RangeSliderComponent
									{...sliderProps}
									range
									value={[rating.from, rating.to]}
								/>
							)}

							{props.rightItems?.(index)}
						</FlexWrapper>
					</>
				);
			}}
		/>
	);
}
