import * as installationApi from "../../../api/installation";
import { StepText, StepTitle } from "../WizardLayout";
import { cloneDeep, _ } from "lodash";
import BeaconLookup from "../BeaconLookup";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import LoadingPlaceholder from "../../report/LoadingPlaceholder";
import { useFetchData } from "../../../hooks";
import {
	VALIDATION_TYPE_OPTIONS,
	VALIDATION_TYPES,
	VALIDATION_WIZARD_TYPE,
	VALIDATION_WIZARD_TYPE_DISPLAY,
} from "../../../helpers/constants";
import FlexWrapperCol from "../../layout/FlexWrapperCol";
import RangeGroup from "../../layout/RangeGroup";
import { formatDiscountTypeDisplay, formatFee } from "../../../helpers/format";
import styled from "styled-components";
import { ChooseValidationRate } from "./ValidationRate";
import { ChooseSpaces } from "./ValidationSpace";

const Label = styled.div`
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 4px;
	margin-top: -32px;
`;

const getTypeDisplay = (type, isLowercase) => {
	const display = VALIDATION_WIZARD_TYPE_DISPLAY[type];
	return isLowercase ? display.toLowerCase() : display;
};

function getButton(
	name,
	color,
	onClick,
	isDisabled,
	isEditing,
	goTo,
	isValidationType,
	keyStrokeHandler
) {
	let label = isEditing
		? isValidationType
			? "Next"
			: "Review"
		: `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
	return (
		<Button
			key={name}
			color={isEditing ? "blue" : color}
			onClick={
				isEditing
					? () => (isValidationType ? goTo("validationValue") : goTo("summary"))
					: onClick
			}
			disabled={isDisabled}
			keyStrokeHandler={keyStrokeHandler}
		>
			{label}
		</Button>
	);
}

export function location({
	close,
	next,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.location;
	const typeDisplay = getTypeDisplay(values.type, true);

	return {
		id: "location",
		label: "Location",
		render: () => (
			<div>
				<StepTitle>What is the location of the {typeDisplay}?</StepTitle>
				<EditableInputField
					type="text"
					name="location"
					value={values.location || ""}
					useLabel="Location"
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("cancel", "red", close)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function uniqueId({
	previous,
	next,
	isSubmitting,
	values,
	goTo,
	wizardProps,
	setFieldValue,
	keyStrokeHandler,
}) {
	const isIncomplete =
		isSubmitting ||
		!values.uniqueId ||
		!values.uniqueId === "" ||
		!values.uniqueIdValid;

	return {
		id: "uniqueId",
		label: "Unique ID",
		render: () => (
			<div>
				<StepTitle>What is the Unique ID of the beacon?</StepTitle>
				<BeaconLookup
					uniqueId={values.uniqueId || ""}
					organizationId={wizardProps.organizationId}
					mode={"validationBeacon"}
					onChange={(value) => {
						setFieldValue("uniqueId", value.uniqueId);
						setFieldValue("uniqueIdValid", value.uniqueIdValid);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function selfValidation({
	previous,
	next,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting;
	const typeDisplay = getTypeDisplay(values.type, true);

	return {
		id: "selfValidation",
		label: " Self Validation",
		render: () => (
			<div>
				<StepTitle>Is this {typeDisplay} for self validation?</StepTitle>
				<div>
					If this validation should not stack with other validations then choose
					Yes. <br />
					<br />
					Note that this will only stop it stacking with other {typeDisplay}s
					with this setting turned on and that stacking rules only apply if the
					site has stacking validation enabled.
				</div>
				<MultiChoiceButton
					selected={!values.selfValidation}
					onClick={() => {
						setFieldValue("selfValidation", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.selfValidation}
					onClick={() => {
						setFieldValue("selfValidation", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function beaconType({
	previous,
	next,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isGroupAccess = values.validationType === "GroupAccess";
	return {
		id: "beaconType",
		label: "Type",
		render: () => (
			<div>
				<StepTitle>Is this beacon to add users to a group?</StepTitle>
				<div>
					Users will be able to scan this beacon to be automatically added to a
					group, granting them access to private sites or special pricing.
				</div>
				<MultiChoiceButton
					selected={!isGroupAccess}
					onClick={() => {
						setFieldValue("validationType", "");
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={isGroupAccess}
					onClick={() => {
						setFieldValue("validationType", "GroupAccess");
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						() => {
							if (isGroupAccess) {
								next();
							} else {
								goTo("selfValidation");
							}
						},
						isSubmitting,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function groupAccess({
	previous,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	goTo,
	keyStrokeHandler,
}) {
	return {
		id: "beaconGroupAccess",
		label: "Group Access",
		render: () => (
			<div>
				<StepTitle>Select a group</StepTitle>
				<Dropdown
					options={wizardProps.organizationAccessGroups.map((group) => ({
						label: group.Name,
						value: group.OrganizationAccessGroupID,
					}))}
					value={values.accessGroup}
					onChange={(value) => {
						setFieldValue("accessGroup", value);
					}}
					isClearable
					isDisabled={values.availableSites.length}
				/>
				<StepTitle style={{ marginTop: "24px" }}>
					Or a list of sites to include
				</StepTitle>
				<Dropdown
					isMulti={true}
					options={wizardProps.availableSites.map((site) => ({
						label: site.name,
						value: site.id,
					}))}
					value={values.validationSites.map((site) => ({
						value: site.id,
						label: site.name,
					}))}
					onChange={(value) => {
						setFieldValue(
							"validationSites",
							value.map((option) => ({ id: option.value, name: option.label }))
						);
					}}
					isDisabled={!!values.accessGroup}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						() => goTo("summary"),
						isSubmitting ||
							(!values.accessGroup && !values.availableSites.length),
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function sites({
	next,
	previous,
	setFieldValue,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const availableOptions = [{ label: "All Sites", value: 0 }].concat(
		values.availableSites.map((site) => ({
			label: site.name,
			value: site.id,
		}))
	);

	const isIncomplete = isSubmitting || !values.validationSites?.length;

	const getBeaconSiteWithRateId = (value) => {
		const validationSites = cloneDeep(values.validationSites);
		let result = [];

		value.forEach((d) => {
			validationSites.forEach((beaconSite) => {
				if (beaconSite.id === d.value && beaconSite.rateId) {
					d.rateId = beaconSite.rateId;
				}
			});
			result.push({
				id: d.value || d.id,
				name: d.label || d.name,
				rateId: d.rateId,
			});
		});

		return result;
	};

	const getRangeData = (value) => {
		const data = [];

		value?.forEach((d) => {
			const element = {
				siteId: d.value || d.id,
				data: [
					{ lowerLimit: 0, upperLimit: 100, fee: 0, rateId: null },
					{ lowerLimit: 101, upperLimit: 150, fee: 0, rateId: null },
					{ lowerLimit: 151, upperLimit: 201, fee: 0, rateId: null },
				],
			};

			data.push(element);
		});

		return data;
	};

	return {
		id: "sites",
		label: `Select Sites`,
		render: () => (
			<div>
				<StepTitle>Select sites</StepTitle>

				<Dropdown
					isMulti={true}
					options={availableOptions}
					value={values.validationSites.map((d) => ({
						label: d.name,
						value: d.id,
					}))}
					onChange={(options) => {
						const isAllSites = options.find((option) => option.value === 0);
						values.leaseParkIds = [];

						if (isAllSites) {
							setFieldValue(
								"validationSites",
								getBeaconSiteWithRateId(values.availableSites)
							);

							setFieldValue("ranges", getRangeData(values.availableSites));

							return;
						}

						setFieldValue(
							"validationSites",
							options ? getBeaconSiteWithRateId(options) : []
						);

						setFieldValue("ranges", getRangeData(options));
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() => (values.editFromSummary ? goTo("spaces") : next())}
						disabled={isSubmitting || isIncomplete}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
}

export function spaces({
	next,
	previous,
	setFieldValue,
	wizardProps,
	values,
	goTo,
	keyStrokeHandler,
	handleChange,
}) {
	return {
		id: "spaces",
		label: "Select Spaces",
		render: () => (
			<div>
				<StepTitle>Select spaces</StepTitle>
				<ChooseSpaces
					setFieldValue={setFieldValue}
					wizardProps={wizardProps}
					values={values}
					handleChange={handleChange}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() =>
							values.editFromSummary ? goTo("validationType") : next()
						}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
}

export function validationType({
	previous,
	next,
	isSubmitting,
	handleChange,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.validationType;

	return {
		id: "validationType",
		label: "Validation Type",
		render: () => (
			<div>
				<StepTitle>What is the validation type?</StepTitle>
				<Dropdown
					options={values.validationTypeOptions}
					value={
						values.validationTypeOptions.find(
							(e) => e.value === values.validationType
						) || ""
					}
					onChange={(value) =>
						handleChange({
							target: {
								name: "validationType",
								value: value.value,
							},
						})
					}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						true,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

const getRatesOptions = (siteId, _rates) => {
	let options = [];

	_rates
		.filter((rate) => rate.IsValidationRate && rate.SiteID === siteId)
		.forEach((rate) =>
			options.push({
				value: rate.RateID,
				label: `${rate.RateID} - ${rate.Name}`,
			})
		);

	return options;
};

const getLeaseRateOptions = (siteId, leaseRates) => {
	const options = [
		{ label: "Default", options: [{ value: null, label: "Default Rate" }] },
		{ label: "Months", options: [] },
		{ label: "Days", options: [] },
		{ label: "Hours", options: [] },
	];

	leaseRates
		.filter(
			(rate) =>
				rate.IsValidationRate &&
				rate.leaseParks.some((leasePark) =>
					leasePark.sites.some((site) => site.SiteID === siteId)
				)
		)
		.forEach((rate) => {
			const group = options.find((o) => o.label === rate.DurationType);
			if (group) {
				group.options.push({
					value: rate.LeaseRateID,
					label: `${rate.LeaseRateID} - ${rate.Name}`,
				});
			}
		});

	return options;
};

// TODO: Delete after testing
// function ChooseValidationRate(props) {
// 	const { data: rates, isLoading } = useFetchData(
// 		[],
// 		installationApi.getCurrentRatesByOrganizationId,
// 		[props.organizationId],
// 		[]
// 	);

// 	const handleValueChange = (value, key) => {
// 		const _sites = cloneDeep(props.validationSites);
// 		_sites[key].id = _sites[key].id || _sites[key].SiteID;
// 		_sites[key].rateId = value;

// 		return _sites;
// 	};

// 	return (
// 		<div>
// 			{!isLoading &&
// 				props.validationSites?.map((beaconSite, key) => {
// 					const options = getRatesOptions(beaconSite.id, rates);
// 					return (
// 						<div key={key} style={{ marginTop: "15px" }}>
// 							<StepText>{beaconSite.name}</StepText>
// 							{options.length ? (
// 								<div style={{ marginTop: "5px" }}>
// 									<Dropdown
// 										options={options}
// 										value={options.find((e) => e.value === beaconSite.rateId)}
// 										isDisabled={options.length === 0}
// 										onChange={(value) => {
// 											props.handleChange({
// 												target: {
// 													name: "validationSites",
// 													value: handleValueChange(value.value, key),
// 												},
// 											});
// 										}}
// 									/>
// 								</div>
// 							) : (
// 								<p>
// 									No validation rate available. Please add a validation rate in{" "}
// 									the Rates page or remove the site from this beacon.
// 								</p>
// 							)}
// 						</div>
// 					);
// 				})}
// 		</div>
// 	);
// }

function ChooseEmissionsValue(props) {
	const { values, setFieldValue } = props;

	const { data: rates, loading } = useFetchData(
		[],
		installationApi.getCurrentRatesByOrganizationId,
		[props.organizationId],
		[]
	);

	const { data: leaseRates, isLoading } = useFetchData(
		[],
		installationApi.getLeaseRatesForOrganizationId,
		[props.organizationId],
		[]
	);

	const typeLabel = formatDiscountTypeDisplay(values.validationType);

	return (
		<div>
			{loading || isLoading ? (
				<LoadingPlaceholder noCardWrapper={true} />
			) : (
				<>
					<StepTitle>
						What is the{" "}
						{values.validationType.includes("Duration")
							? typeLabel.toLowerCase()
							: typeLabel.toLowerCase() + " amount"}
						?
					</StepTitle>
					{values.validationSites?.map((site, index) => {
						const siteIndex = values.ranges.findIndex(
							(range) => range.siteId === site.id
						);

						const ranges = values.ranges?.[siteIndex]?.data;

						const options = getRatesOptions(site.id, rates);
						const leaseParkOptions = getLeaseRateOptions(site.id, leaseRates);

						const bookingRateValueGroups = leaseParkOptions?.map((option) => {
							return {
								type: option.label,
								leaseRateIds: option.options?.map((o) => o.value),
							};
						});

						return (
							<div key={index} style={{ marginTop: "15px" }}>
								<StepText>{site.name}</StepText>

								{values.validationType === "Rate" && options.length === 0 ? (
									<p>
										No transient validation rates available. To link transient
										rates to this validation, please add a validation rate in
										the Rates page.
									</p>
								) : null}

								{values.validationType === "Rate" &&
								leaseParkOptions.length === 0 ? (
									<p>
										No booking validation rates available. To link booking rates
										to this validation, please add a validation rate in the
										Rates page.
									</p>
								) : null}

								{values.validationType !== "Rate" ||
								(values.validationType === "Rate" &&
									(leaseParkOptions.length !== 0 || options.length !== 0)) ? (
									<RangeGroup
										data={ranges?.map((range) => ({
											from: range.lowerLimit,
											to: range.upperLimit ?? 201,
										}))}
										max={201}
										onChange={(data) => {
											setFieldValue(
												"ranges",
												values.ranges.map((range) => {
													if (range.siteId === site.id) {
														range.data = data.map((d) => ({
															lowerLimit: d.from,
															upperLimit: d.to,
														}));
													}

													return range;
												})
											);
										}}
										rightItems={(rangeIndex) => {
											const updateRanges = (value, isBookingRate) => {
												const rangesClone = cloneDeep(values.ranges);
												const rateRanges = cloneDeep(ranges);

												if (isBookingRate) {
													rateRanges[rangeIndex].leaseRateIds = value;
												} else {
													rateRanges[rangeIndex].rateId = value;
												}

												rangesClone[siteIndex].data = rateRanges;

												setFieldValue("ranges", rangesClone);
											};
											if (values.validationType === "Rate") {
												return (
													<div style={{ display: "flex", gap: "16px" }}>
														{options.length > 0 && (
															<div style={{ flexGrow: "1" }}>
																{rangeIndex === 0 && (
																	<Label>Transient Rate</Label>
																)}
																<Dropdown
																	options={[
																		{ label: "Default Rate", value: null },
																		...options,
																	]}
																	value={options.find(
																		(e) =>
																			e.value === ranges[rangeIndex]?.rateId
																	)}
																	isDisabled={options.length === 0}
																	onChange={(value) => {
																		updateRanges(value.value, false);
																	}}
																/>
															</div>
														)}

														{leaseParkOptions.length > 0 && (
															<div style={{ flexGrow: "1" }}>
																{rangeIndex === 0 && (
																	<Label>Booking Rates</Label>
																)}
																<Dropdown
																	isMulti={true}
																	options={leaseParkOptions}
																	isDisabled={leaseParkOptions.length === 0}
																	value={leaseParkOptions.map((option) => {
																		const selectedLeaseRateIds =
																			ranges[rangeIndex]?.leaseRateIds || [];

																		return option.options.find((o) =>
																			selectedLeaseRateIds.includes(o.value)
																		);
																	})}
																	onChange={(value) => {
																		const currentlySelectedLeaseRateIds =
																			values.leaseRates?.map(
																				(lr) => lr.value
																			) || [];

																		const newLeaseRateId = _.difference(
																			value.map((v) => v.value),
																			currentlySelectedLeaseRateIds
																		)?.[0];

																		const valueGroup =
																			bookingRateValueGroups.find((brvg) =>
																				brvg.leaseRateIds.includes(
																					newLeaseRateId
																				)
																			);

																		if (
																			valueGroup &&
																			currentlySelectedLeaseRateIds.some((lr) =>
																				valueGroup.leaseRateIds.includes(lr)
																			)
																		) {
																			const filteredValue = value.filter(
																				(v) =>
																					!valueGroup.leaseRateIds.includes(
																						v.value
																					) || v.value === newLeaseRateId
																			);

																			updateRanges(
																				filteredValue.map((fv) => fv.value),
																				true
																			);
																			return;
																		}

																		updateRanges(
																			value.map((fv) => fv.value),
																			true
																		);
																	}}
																/>
															</div>
														)}
													</div>
												);
											}

											return (
												<EditableInputField
													key={rangeIndex}
													type="number"
													min={0}
													max={
														values.validationType === "Percentage" ? 100 : 1000
													}
													name="validationValue"
													value={ranges[rangeIndex]?.fee || 0}
													useLabel={values.validationType}
													onChange={(value) => {
														const rangesClone = cloneDeep(values.ranges);
														const feeRanges = cloneDeep(ranges);

														feeRanges[rangeIndex].fee = parseFloat(
															value.target.value
														);
														rangesClone[siteIndex].data = feeRanges;

														setFieldValue("ranges", rangesClone);
													}}
												/>
											);
										}}
									/>
								) : null}
							</div>
						);
					})}
				</>
			)}
		</div>
	);
}

export function validationValue({
	values,
	errors,
	touched,
	previous,
	next,
	handleChange,
	isSubmitting,
	goTo,
	keyStrokeHandler,
	setFieldValue,
}) {
	let isIncomplete;

	if (values.type === VALIDATION_WIZARD_TYPE.AUTO_VALIDATION) {
		return {
			id: "validationValue",
			label: "Validation Value",
			render: () => (
				<ChooseEmissionsValue
					organizationId={values.organizationId}
					values={values}
					setFieldValue={setFieldValue}
				/>
			),
			footer: () => (
				<WizardNavigation
					leftItems={getButton("back", "blue", previous)}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isSubmitting,
							values.editFromSummary,
							goTo,
							undefined,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	}

	if (values.validationType === "Rate") {
		const isCompleted = values.validationSites?.some((validationSite) => {
			return !isNaN(validationSite.rateId);
		});

		isIncomplete = isSubmitting || !isCompleted;

		return {
			id: "validationValue",
			label: "Validation Rate",
			render: () => (
				<div>
					<StepTitle>
						Which validation rate should be applied to each site?
					</StepTitle>
					<ChooseValidationRate
						validationSites={values.validationSites}
						handleChange={handleChange}
						organizationId={values.organizationId}
					/>
				</div>
			),
			footer: () => (
				<WizardNavigation
					leftItems={getButton("back", "blue", previous)}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isIncomplete,
							values.editFromSummary,
							goTo,
							undefined,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	} else {
		isIncomplete =
			isSubmitting || !values.validationValue || values.validationValue < 0.1;

		const typeLabel = formatDiscountTypeDisplay(values.validationType);

		return {
			id: "validationValue",
			label: `${
				values.validationType === "GroupAccess" ? "Group Access" : typeLabel
			}`,
			render: () => (
				<div>
					<StepTitle>
						What is the{" "}
						{values.validationType.includes("Duration")
							? typeLabel.toLowerCase()
							: typeLabel.toLowerCase() + " amount"}
						?
					</StepTitle>
					<EditableInputField
						type="number"
						min={0.1}
						name="validationValue"
						value={values.validationValue || ""}
						useLabel={values.validationType}
						error={
							errors.validationValue && touched.validationValue
								? errors.validationValue
								: null
						}
					/>
				</div>
			),
			footer: () => (
				<WizardNavigation
					leftItems={
						!values.editFromSummary && [getButton("back", "blue", previous)]
					}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isIncomplete,
							values.editFromSummary,
							goTo,
							undefined,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	}
}

export function summary({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps: { mode, availableSpaces },
	keyStrokeHandler,
}) {
	const isGroupAccess = values.validationType === "GroupAccess";
	const isBeacon = values.type === VALIDATION_WIZARD_TYPE.BEACON;
	const items = [
		{
			title: "Location",
			value: values.location,
			key: "location",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("location");
			},
		},
		isBeacon
			? {
					title: "Unique ID",
					value: values.uniqueId,
					key: "uniqueId",
			  }
			: null,
		{
			title: `Validation type`,
			value:
				values.validationType === "GroupAccess"
					? "Group Access"
					: VALIDATION_TYPE_OPTIONS.find(
							(option) => option.value === values.validationType
					  )?.label,
			key: "validationType",
			edit: () => {
				setFieldValue("editFromSummary", true);
				if (isGroupAccess) {
					goTo("beaconType");
				} else {
					goTo("validationType");
				}
			},
		},
		{
			title: "Sites",
			value: values.validationSites?.map((site) => site.name).join(", "),
			key: "sites",
			edit: () => {
				setFieldValue("editFromSummary", true);
				if (isGroupAccess) {
					goTo("beaconGroupAccess");
				} else {
					goTo("sites");
				}
			},
		},
		!isBeacon && {
			title: "Spaces",
			value: _.uniqBy(
				Object.values(values.leaseParkIds).flatMap((id) => {
					let space = availableSpaces.flatMap((lp) => {
						return lp.Spaces.find((s) => s.LeaseParkID === id);
					});

					return space.map((s) => s?.Name).filter((n) => !!n);
				})
			).join(", "),
			key: "spaces",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("spaces");
			},
		},
	].filter((item) => item);

	if (isGroupAccess) {
		items.push({
			title: "Group",
			value: values.accessGroup?.label,
			key: "accessGroup",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("beaconGroupAccess");
			},
		});
	} else {
		const getValidationValue = () => {
			if (values.type === VALIDATION_WIZARD_TYPE.AUTO_VALIDATION) {
				if (values.validationType === VALIDATION_TYPES.RATE) {
					return "Multiple rates";
				}

				return (
					values.ranges
						?.map((range) => {
							const site = values.validationSites.find(
								(s) => s.id === range.siteId
							);

							const rangeValues = range.data.map((d) => {
								const feeValue = formatFee(d, values.validationType);
								return `${d.lowerLimit} to ${d.upperLimit} - ${feeValue}`;
							});

							return `${site.name}: ${rangeValues.join(", ")}`;
						})
						.join(", ") ?? ""
				);
			}

			if (values.validationType === VALIDATION_TYPES.RATE) {
				return values.validationSites
					.filter((site) => site.rateId)
					.map((site) => `${site.name} - ${site.rateId}`)
					.join(", ");
			}

			if (values.validationType === VALIDATION_TYPES.FIXED) {
				return `-$${values.validationValue}`;
			}

			if (
				values.validationType === VALIDATION_TYPES.DURATIONDAYS ||
				values.validationType === VALIDATION_TYPES.DURATIONHOURS
			) {
				return values.validationType === VALIDATION_TYPES.DURATIONDAYS
					? `${values.validationValue} days free`
					: `${values.validationValue} hours free`;
			}

			return `-${values.validationValue}%`;
		};

		items.push(
			{
				title: "Self Validation",
				value: values.selfValidation ? "Yes" : "No",
				key: "selfValidation",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("selfValidation");
				},
			},
			{
				title:
					values.validationType === "Rate"
						? "Rate"
						: `${
								VALIDATION_TYPE_OPTIONS.find(
									(option) => option.value === values.validationType
								)?.label
						  }`,
				value: getValidationValue(),
				key: "validationValue",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("validationValue");
				},
			}
		);
	}

	let button = getButton(
		"submit",
		"green",
		handleSubmit,
		isSubmitting,
		undefined,
		undefined,
		undefined,
		keyStrokeHandler
	);

	if (mode === "delete") {
		items.forEach((item) => delete item.edit);
		button = getButton("remove", "red", handleSubmit, isSubmitting);
	}

	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable items={items} />
			</div>
		),
		footer: () => <WizardNavigation rightItems={[button]} />,
	};
}

export function download({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	keyStrokeHandler,
	close,
}) {
	const button = getButton(
		"download",
		"green",
		handleSubmit,
		isSubmitting || !values.format || !values.security,
		undefined,
		undefined,
		undefined,
		keyStrokeHandler
	);

	const options = [
		{ label: "PDF", value: "pdf" },
		{ label: "SVG", value: "svg" },
	];

	const security = [
		{ label: "Color", value: "color" },
		{ label: "Logo", value: "logo" },
	];

	return {
		id: "download",
		label: "Download",
		render: () => (
			<FlexWrapperCol>
				<div>
					<StepTitle>What file format would you like to download?</StepTitle>
					<Dropdown
						options={options}
						value={options.find((e) => e.value === values.format) || ""}
						onChange={(value) => {
							setFieldValue("format", value.value);
						}}
					/>
				</div>
				<div>
					<StepTitle>
						Which security option would you like to display?
					</StepTitle>

					<Dropdown
						isMulti={true}
						value={values.security}
						options={security}
						onChange={(value) => {
							setFieldValue("security", value);
						}}
					/>
				</div>
			</FlexWrapperCol>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[getButton("cancel", "red", close)]}
				rightItems={[button]}
			/>
		),
	};
}
