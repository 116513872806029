import gql from "graphql-tag";
import { useMutateData, useQueryData } from "../../hooks";

export function useGetValidationConfigurationForOrganization(
	paginate,
	skipQuery
) {
	return useQueryData(
		gql`
			query ($page: PageInfo!, $cursor: Int) {
				getValidationConfigurationForOrganization(
					page: $page
					cursor: $cursor
				) {
					previousEvent
					trueLength
					validationConfigurations {
						ValidationConfigurationID
						Location
						ValidationType
						ValidationMethod
						IsSelfValidation
						CreatedOn
						Sites {
							SiteID
							Name
						}
						Rates {
							RateID
							SiteID
							Name
						}
						LeaseRates {
							LeaseRateID
							Name
						}
						EmissionsRanges {
							LowerLimit
							UpperLimit
							ValidationValue
							RateID
							LeaseRateIDs
							SiteID
						}
					}
				}
			}
		`,
		{
			page: {
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);
}

export function useCreateValidationQRCode() {
	return useMutateData(
		gql`
			mutation ($qrCode: ValidationQRCodeInput!) {
				createValidationQRCode(qrCode: $qrCode)
			}
		`
	);
}

export function useUpdateValidationQRCode() {
	return useMutateData(
		gql`
			mutation ($qrCode: ValidationQRCodeInput!) {
				updateValidationQRCode(qrCode: $qrCode)
			}
		`
	);
}

export function useDeleteValidationQRCode() {
	return useMutateData(
		gql`
			mutation ($qrCodeId: Int!) {
				deleteValidationQRCode(qrCodeId: $qrCodeId)
			}
		`
	);
}

export function useCreateValidationConfiguration() {
	return useMutateData(
		gql`
			mutation ($validationConfiguration: ValidationConfigurationInput!) {
				createValidationConfiguration(
					validationConfiguration: $validationConfiguration
				)
			}
		`
	);
}

export function useUpdateValidationConfiguration() {
	return useMutateData(
		gql`
			mutation (
				$validationConfigurationId: Int!
				$validationConfiguration: ValidationConfigurationInput!
			) {
				updateValidationConfiguration(
					validationConfigurationId: $validationConfigurationId
					validationConfiguration: $validationConfiguration
				)
			}
		`
	);
}

export function useDeleteValidationConfiguration() {
	return useMutateData(
		gql`
			mutation ($validationConfigurationId: Int!) {
				deleteValidationConfiguration(
					validationConfigurationId: $validationConfigurationId
				)
			}
		`
	);
}
