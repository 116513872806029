import * as InstallationApi from "../../../api/installation";
import * as WebPayApi from "../../../api/web-pay";
import * as Yup from "yup";
import * as steps from "./steps";
import Alert from "react-s-alert";
import ErrorBoundary from "../../../containers/ErrorBoundary";
import PropTypes from "prop-types";
import React from "react";
import WizardLayout from "../WizardLayout";
import {
	VALIDATION_WIZARD_TYPE,
	VALIDATION_WIZARD_TYPE_DISPLAY,
} from "../../../helpers/constants";
import {
	useCreateValidationConfiguration,
	useCreateValidationQRCode,
	useDeleteValidationConfiguration,
	useDeleteValidationQRCode,
	useUpdateValidationConfiguration,
	useUpdateValidationQRCode,
} from "../../../api/validations/validations";

function getWizard({
	mode,
	type,
	organizationId,
	organizationAccessGroups,
	beacon,
	qrCode,
	validationConfiguration,
	availableSites,
	availableSpaces,
	validationTypeOptions,
	rateData,
}) {
	const isBeacon = type === VALIDATION_WIZARD_TYPE.BEACON;
	const isAutoValidation = type === VALIDATION_WIZARD_TYPE.AUTO_VALIDATION;
	const isQRCode = type === VALIDATION_WIZARD_TYPE.QR_CODE;

	const accessGroup = organizationAccessGroups?.find(
		(group) =>
			group.OrganizationAccessGroupID === beacon?.OrganizationAccessGroupID
	);

	let data;
	if (isBeacon) {
		data = {
			id: beacon?.BeaconID,
			idName: "beaconId",
			...beacon,
		};
	}

	if (isAutoValidation) {
		data = {
			id: validationConfiguration?.ValidationConfigurationID,
			idName: "validationConfigurationId",
			...validationConfiguration,
		};
	}

	if (isQRCode) {
		data = {
			id: qrCode?.QRCodeID,
			idName: "qrCodeId",
			...qrCode,
		};
	}

	const fields = [
		{
			name: "organizationId",
			value: organizationId,
			validator: Yup.number(),
		},
		{
			name: data.idName,
			value: data.id,
			validator: Yup.number().nullable(),
		},
		{
			name: "location",
			value: data.Location,
			validator: Yup.string().nullable(),
		},
		isBeacon
			? {
					name: "uniqueId",
					value: data.UniqueID,
					validator: Yup.string(),
			  }
			: null,
		{
			name: "selfValidation",
			value: !!data.IsSelfValidation,
			validator: Yup.boolean(),
		},
		{
			name: "validationSites",
			value: data.sites || [],
			validator: Yup.array(),
		},
		{
			name: "availableSites",
			value: availableSites,
			validator: Yup.array(),
		},
		{
			name: "availableSpaces",
			value: availableSpaces,
			validator: Yup.array(),
		},
		{
			name: "validationType",
			value: data.ValidationType,
			validator: Yup.string(),
		},
		{
			name: "validationTypeOptions",
			value: validationTypeOptions,
			validator: Yup.array(),
		},
		!isAutoValidation
			? {
					name: "validationValue",
					value: data.ValidationValue,
					validator: Yup.mixed().when("validationType", {
						is: (val) => val === "Rate" || val === "GroupAccess",
						then: Yup.mixed().notRequired(),
						otherwise: Yup.number()
							.positive()
							.required("Please supply a value for this beacon")
							.typeError("Please specify a number"),
					}),
			  }
			: null,
		{
			name: "rateData",
			value: rateData,
			validator: Yup.array(),
		},
		!isAutoValidation
			? {
					name: "rateName",
					value: data.rate || "",
					validator: Yup.string(),
			  }
			: null,
		isBeacon
			? {
					name: "uniqueIdValid",
					value: true,
			  }
			: null,
		isBeacon
			? {
					name: "accessGroup",
					value: accessGroup
						? {
								value: accessGroup.OrganizationAccessGroupID,
								label: accessGroup.Name,
						  }
						: null,
					validator: Yup.object().nullable(),
			  }
			: null,
		{
			name: "type",
			value: type,
			validator: Yup.string(),
		},
		{
			name: "format",
			value: null,
			validator: Yup.string().nullable(),
		},
		{
			name: "security",
			value: null,
			validator: Yup.object().nullable(),
		},
		isAutoValidation
			? {
					name: "ranges",
					value:
						data.Sites?.map((site) => ({
							siteId: site.SiteID,
							data: data.EmissionsRanges?.map((range) => ({
								lowerLimit: range.LowerLimit,
								upperLimit: range.UpperLimit,
								fee: range.ValidationValue,
								rateId: range.RateID,
							})),
						})) || [],
					validator: Yup.array().nullable(),
			  }
			: null,
		!isBeacon && {
			name: "leaseParkIds",
			value:
				qrCode?.LeaseParks?.map((lp) => ({
					lp,
					key: availableSpaces.find((slp) =>
						slp.Spaces.some((s) => s.LeaseParkID === lp.LeaseParkID)
					)?.SiteID,
				}))
					.filter((item) => item.key !== undefined)
					.reduce((acc, item) => {
						acc[item.key] = item.lp.LeaseParkID;
						return acc;
					}, {}) || {},
			validator: Yup.array().nullable(true),
		},
	].filter((field) => field);

	switch (mode) {
		case "add":
			return {
				title: `Add Validation ${VALIDATION_WIZARD_TYPE_DISPLAY[type]}`,
				initialStep: 0,
				steps: [
					steps.location,
					isBeacon ? steps.uniqueId : null,
					isBeacon ? steps.beaconType : null,
					isBeacon ? steps.groupAccess : null,
					!isAutoValidation ? steps.selfValidation : null,
					steps.validationType,
					steps.sites,
					isQRCode ? steps.spaces : null,
					steps.validationValue,
					steps.summary,
				].filter((step) => step),
				fields,
			};
		case "update": {
			const updateSteps = [
				steps.location,
				isBeacon ? steps.uniqueId : null,
				isBeacon ? steps.beaconType : null,
				isBeacon ? steps.groupAccess : null,
				steps.selfValidation,
				steps.validationType,
				steps.sites,
				isQRCode ? steps.spaces : null,
				steps.validationValue,
				steps.summary,
			].filter((step) => step);

			return {
				title: `Update Validation ${VALIDATION_WIZARD_TYPE_DISPLAY[type]}`,
				initialStep: updateSteps.length - 1,
				steps: updateSteps,
				fields,
			};
		}

		case "delete":
			return {
				title: `Delete Validation  ${VALIDATION_WIZARD_TYPE_DISPLAY[type]}`,
				initialStep: 0,
				steps: [steps.summary],
				fields,
			};

		case "download":
			return {
				title: "Download Validation QR Code",
				initialStep: 0,
				steps: [steps.download],
				fields,
			};
	}
}

ValidationWizard.propTypes = {
	mode: PropTypes.oneOf(["add", "update", "delete"]).isRequired,
	availableSites: PropTypes.arrayOf(PropTypes.object).isRequired,
	close: PropTypes.func.isRequired,
	beacon: PropTypes.object,
	qrCode: PropTypes.object,
};

ValidationWizard.defaultProps = {
	beacon: {},
	qrCode: {},
};

function ValidationWizard(props) {
	const isBeacon = props.type === VALIDATION_WIZARD_TYPE.BEACON;
	const isAutoValidation =
		props.type === VALIDATION_WIZARD_TYPE.AUTO_VALIDATION;

	const wizard = getWizard({
		mode: props.mode,
		type: props.type,
		organizationId: props.organizationId,
		organizationAccessGroups: props.organizationAccessGroups,
		beacon: props.beacon,
		qrCode: props.qrCode,
		validationConfiguration: props.validationConfiguration,
		availableSites: props.availableSites,
		availableSpaces: props.availableSpaces,
		validationTypeOptions: props.validationTypeOptions,
		rateData: props.rateData,
	});

	const buildValidationObject = (values) => {
		const baseValidation = {
			isSelfValidation: !!values.selfValidation,
			validationType: values.validationType,
			accessGroupId: values.accessGroup?.value,
			location: values.location,
		};

		if (isAutoValidation) {
			const ranges =
				values.ranges?.flatMap((range) =>
					range.data.map((d) => ({
						LowerLimit: d.lowerLimit,
						UpperLimit: d.upperLimit,
						ValidationValue: d.fee,
						RateID: d.rateId,
						SiteID: range.siteId,
						LeaseRateIDs: d.leaseRateIds?.filter(Boolean),
					}))
				) || [];

			return {
				...baseValidation,
				emissionsRanges: ranges,
			};
		}

		if (isBeacon) {
			return {
				...baseValidation,
				beaconId: values.beaconId,
			};
		}

		return {
			...baseValidation,
			qrCodeId: values.qrCodeId,
			leaseParkIds: Object.values(values.leaseParkIds).filter((v) => !!v),
		};
	};

	const createValidationQRCode = useCreateValidationQRCode();
	const updateValidationQRCode = useUpdateValidationQRCode();
	const deleteValidationQRCode = useDeleteValidationQRCode();

	const createValidationConfiguration = useCreateValidationConfiguration();
	const updateValidationConfiguration = useUpdateValidationConfiguration();
	const deleteValidationConfiguration = useDeleteValidationConfiguration();

	const handleRateValidation = (values, validation) => {
		validation.sitesWithRates = [];
		values.validationSites.map((site) => {
			validation.sitesWithRates.push({
				id: site.id || site.SiteID,
				rateId: site.rateId,
			});
		});
	};

	const handleNonRateValidation = (values, validation) => {
		validation.validationValue = values.validationValue;

		validation.siteIds = values.validationSites.map((site) => site.id);
	};

	const handleAddMode = async (values, validation) => {
		if (isAutoValidation) {
			return await createValidationConfiguration({
				variables: {
					validationConfiguration: {
						...validation,
						organizationId: values.organizationId,
					},
				},
			});
		}

		if (isBeacon) {
			validation.beaconId = values.uniqueId;
			await InstallationApi.createValidationBeacon(
				values.organizationId,
				validation
			);
			return true;
		}

		await createValidationQRCode({
			variables: {
				qrCode: { ...validation, organizationId: values.organizationId },
			},
		});
		return true;
	};

	const handleUpdateMode = async (values, validation) => {
		if (isAutoValidation) {
			return await updateValidationConfiguration({
				variables: {
					validationConfigurationId: values.validationConfigurationId,
					validationConfiguration: {
						...validation,
					},
				},
			});
		}

		if (isBeacon) {
			await InstallationApi.updateValidationBeacon(
				values.organizationId,
				values.beaconId,
				validation
			);
		} else {
			await updateValidationQRCode({
				variables: {
					qrCode: { ...validation, organizationId: values.organizationId },
				},
			});
		}
	};

	const handleDeleteMode = async (values) => {
		if (isAutoValidation) {
			return await deleteValidationConfiguration({
				variables: {
					validationConfigurationId: values.validationConfigurationId,
				},
			});
		}

		if (isBeacon) {
			await InstallationApi.deleteValidationBeaconById(
				values.organizationId,
				values.beaconId
			);
		} else {
			await deleteValidationQRCode({
				variables: { qrCodeId: values.qrCodeId },
			});
		}
	};

	const handleDownloadMode = async (values) => {
		if (isAutoValidation || isBeacon) {
			Alert.error(
				`Auto validation and beacons have no download media, please select a QR Code validation and try again.`
			);
			return;
		}

		const security = values.security?.reduce((obj, item) => {
			Object.assign(obj, { [item.value]: true });
			return obj;
		}, {});

		const { error } = await WebPayApi.getValidationQRCodeUrl({
			format: values.format,
			security: security,
			qrCodeId: values.qrCodeId,
		});

		if (error) {
			Alert.error(`Could not download QR Code, ${error.message}`);
			return;
		}
	};

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		const validation = buildValidationObject(values);
		let message = "";

		if (values.validationType === "Rate") {
			handleRateValidation(values, validation);
		} else {
			handleNonRateValidation(values, validation);
		}

		try {
			if (props.mode === "add") {
				message = "added";
				const added = await handleAddMode(values, validation);

				if (!added) {
					setSubmitting(false);
					Alert.error("Something went wrong. Please try again.");
					return;
				}
			}

			if (props.mode === "update") {
				message = "updated";
				await handleUpdateMode(values, validation);
			}

			if (props.mode === "delete") {
				message = "deleted";
				await handleDeleteMode(values, validation);
			}

			if (props.mode === "download") {
				message = "downloaded";

				await handleDownloadMode(values);
			}

			setSubmitting(false);
			Alert.success(`${VALIDATION_WIZARD_TYPE_DISPLAY[props.type]} ${message}`);
			props.close(true);
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<ErrorBoundary>
			<WizardLayout
				close={props.close}
				title={wizard.title}
				values={wizard.fields}
				onSubmit={onSubmit}
				steps={wizard.steps}
				initialStep={wizard.initialStep}
				wizardProps={props}
			/>
		</ErrorBoundary>
	);
}

export default ValidationWizard;
