export function rangeCalculations(ranges, startIndex, max) {
	const rangeCount = ranges.length;
	let newRanges = ranges;

	function calcAbove(index, left, right) {
		// These values are for preventing max and min values exceeding the max and min value of the slider
		const minLeft = index * 2;
		const minRight = minLeft + 1;
		let maxRight = max - (rangeCount - 1 - index) * 2 + 1;
		const maxLeft = maxRight;

		if (index === rangeCount - 1) {
			maxRight = maxRight - 1;
		}

		let newRight = right;
		let newLeft = left;

		// Anchor right to min/max
		if (newRight < minRight) newRight = minRight;
		if (newRight > maxRight) newRight = maxRight;

		// Prevent slider overlapping
		if (newLeft > newRight - 1 && index !== rangeCount - 1)
			newLeft = newRight - 1;

		// Anchor left to min/max
		if (newLeft < minLeft) newLeft = minLeft;
		if (newLeft > maxLeft) newLeft = maxLeft;

		newRanges[index] = {
			lower: newLeft,
			upper: newRight,
		};

		if (index === 0) return;

		const nextLeft = ranges[index - 1].lower;

		const nextRight = newLeft - 1;

		calcAbove(index - 1, nextLeft, nextRight);
	}

	function calcBelow(index, left, right) {
		const minLeft = index * 2;
		const minRight = minLeft + 1;
		let maxRight = max - (rangeCount - 1 - index) * 2 + 1;
		const maxLeft = maxRight;

		if (index === rangeCount - 1) {
			maxRight = maxRight - 1;
		}

		let newRight = right;
		let newLeft = left;

		// Anchor left to min/max
		if (newLeft < minLeft) newLeft = minLeft;
		if (newLeft > maxLeft) newLeft = maxLeft;

		// Prevent slider overlapping
		if (newLeft > newRight - 1) newRight = newLeft + 1;

		// Anchor right to min/max
		if (newRight < minRight) newRight = minRight;
		if (newRight > maxRight) newRight = maxRight;

		newRanges[index] = {
			lower: newLeft,
			upper: newRight,
		};

		if (index === rangeCount - 1) return;

		const nextRight = ranges[index + 1].upper;

		const nextLeft = newRight + 1;

		calcBelow(index + 1, nextLeft, nextRight);
	}

	calcAbove(startIndex, ranges[startIndex].lower, ranges[startIndex].upper);
	calcBelow(startIndex, ranges[startIndex].lower, ranges[startIndex].upper);

	return newRanges;
}
